<template>
  <patch-locations-template
    :patch-locations="patchLocations"
    :other-patch-locations="otherPatchLocations"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import PatchLocationsTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-texture/patch-locations/PatchLocationsTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { includes } from '@/modules/questionnaire/api/helpers';

import { OTHER_VALUE_BINDINGS } from '@/modules/questionnaire/api/constants';

const { requiredArrayField, requiredField } = fieldBuilder;

const FIELDS = [requiredArrayField('patchLocations'), requiredField('otherPatchLocations')];

export default {
  name: 'PatchLocations',
  components: {
    PatchLocationsTemplate
  },
  mixins: [makeStep(FIELDS)],
  methods: {
    isFieldVisible(fieldName) {
      if (fieldName === 'otherPatchLocations') {
        return includes(this.patchLocations, OTHER_VALUE_BINDINGS.value);
      }

      return true;
    }
  }
};
</script>
